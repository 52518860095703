import "styles/pages/single-post.scss";
import React from "react";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";

const SingleNews = ({ pageContext }) => {
   const seo = pageContext.seo;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs
            currentLocationLabel={pageContext.title}
            prevLocationLabel="Aktualności"
            prevLocationLink="/aktualnosci"
         />
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-xl-10">
                  <article className="post">
                     <div className="post__wrapper">
                        <img
                           src={
                              pageContext.img
                                 ? pageContext.img.node.sourceUrl
                                 : require("../assets/images/news-placeholder.jpg")
                                      .default
                           }
                           alt={pageContext.img?.node.altText}
                           className="post__img"
                        />
                        <Link
                           to={`/aktualnosci/${pageContext.category[0]?.slug}`}
                           className="post__category"
                           style={{
                              backgroundColor:
                                 pageContext.category[0].newsCategory
                                    .categoryColor,
                           }}
                        >
                           {pageContext.category[0].name}
                        </Link>
                        <div className="post__date">{pageContext.date}</div>
                     </div>
                     <SectionTitle title={pageContext.title} />
                     <div
                        className="post__content"
                        dangerouslySetInnerHTML={{
                           __html: pageContext.content,
                        }}
                     />
                  </article>
               </div>
            </div>
         </div>
      </Layout>
   );
};

export default SingleNews;
